import React from "react"
import Hero, { HeroContent } from "../components/hero"
import LayoutDefault from "../layouts/default"
import Seo from "../components/seo"
import { Row, Content, Article } from "../components/article"

const WorksPage = () => {
  const breadcrumbList = [
    { to: "/", name: "Главная" },
    { to: "/works", name: "Работы" },
  ]

  const content = '<p>Страница находится в разработке...</p>'

  return (
    <LayoutDefault>
      <Seo
        title="Работы"
        description="Работы"
        url="/works"
      />
      <Hero breadcrumbs={breadcrumbList} backLink="/">
        <HeroContent>
          <h1 className="heading">Портфолио</h1>
        </HeroContent>
      </Hero>
      <Row>
        <Content>
          <Article dangerouslySetInnerHTML={{ __html: content }}/>
        </Content>
      </Row>
    </LayoutDefault>
  )
}

export default WorksPage